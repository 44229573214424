<template>
  <div>
    <b-card>

      <div class="container app-view">

        <b-card style="width: 600px; margin: auto;">
          <div class="app_title_box">
            <h4>Contact Content Page</h4>

          </div>
          <b-card-text>
            <validation-observer ref="contactForm">
              <b-form @submit.prevent="createPageContent">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Email"
                      label-for="v-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="v-email"
                          v-model="contact.email"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Phone"
                      label-for="v-phone"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="phone"
                        rules="required"
                      >
                        <b-form-input
                          id="v-phone"
                          v-model="contact.phone"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Phone"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Address"
                      label-for="v-address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="address"
                        rules="required"
                      >
                        <b-form-input
                          id="v-address"
                          v-model="contact.address"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="5">
                    <b-form-group
                      label="City"
                      label-for="v-city"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="city"
                        rules="required"
                      >
                        <b-form-input
                          id="v-city"
                          v-model="contact.city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="City"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group
                      label="State"
                      label-for="v-state"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="state"
                        rules="required"
                      >
                        <b-form-select
                          id="h-category"
                          v-model="contact.state"
                          :options="state_options"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="3">
                    <b-form-group
                      label="Zip code"
                      label-for="v-zip_code"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="zip_code"
                        rules="required"
                      >
                        <b-form-input
                          id="v-zip_code"
                          v-model="contact.zip_code"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Zip code"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col cols="3">
                    <b-form-group
                      label="County"
                      label-for="v-county"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="county"
                        rules="required"
                      >
                        <b-form-select
                          id="v-county"
                          v-model="contact.county_id"
                          :options="county_options"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <div class="mt-4 d-flex align-items-center justify-content-between">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="mt-2"
                    type="submit"
                  >
                    Save Changes
                  </b-button>
                </div>
              </b-form>

            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormSelect, VBModal, BCardText, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
    BForm,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      content: '<p>Enter about here</p>',
      email,
      required,
      contact: {
        email: '',
        phone: '',
        address: '',
        state: '',
        city: '',
        zip_code: '',
      },
    }
  },
  computed: {
    contents() {
      return this.$store.state.page_content.contact_contents
    },
    states() {
      return this.$store.state.state.states
    },
    state_options() {
      return this.states.map(item => {
        const data = { value: item.abbreviation, text: `${item.name}` }
        return data
      })
    },
    counties() {
      return this.$store.state.county.counties
    },
    county_options() {
      return this.counties.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
  },
  watch: {
    '$store.state.page_content.contact_contents': {
      handler(oldValue, newValue) {
        if (oldValue.content !== null) {
          this.contact = oldValue.content
        }
        if (newValue && newValue.content !== null) {
          this.contact = newValue.content
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getContent()
  },
  methods: {
    showModal() {
      this.$bvModal.show('app-modal')
    },
    getContent() {
      return this.$store.dispatch('page_content/getAllContactContent', 'contacts')
    },
    createPageContent() {
      this.$refs.contactForm.validate().then(success => {
        if (success) {
          if (this.contents?.content?.email === undefined || this.contents?.content?.email === null) {
            const data = { name: 'contacts', content: this.contact }
            this.$store.dispatch('page_content/createContent', data)
          } else {
            const data = { id: this.contents.id, name: 'contacts', content: this.contact }

            this.$store.dispatch('page_content/updateContent', data)
          }
        }
      })
    },
    // filterState(id) {
    //   return this.$store.dispatch('county/getCounties', id)
    // },
  },
}
</script>

<style>

</style>
